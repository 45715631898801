import { Types } from '../constants/action-type';

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export type FormType = {
  isFormChanged?: boolean;
};

type FormPayload = {
  [Types.GET_FORM_DIRTY_STATUS]: {
    isFormChanged: boolean;
  };
};

export type FormActions = ActionMap<FormPayload>[keyof ActionMap<FormPayload>];

export const formReducer = (state: FormType, action: FormActions) => {
  switch (action.type) {
    case Types.GET_FORM_DIRTY_STATUS:
      const isFormChanged = action.payload.isFormChanged || false;
      return { ...state, isFormChanged: isFormChanged };
    default:
      return state;
  }
};
