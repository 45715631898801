import React, {  } from 'react';
import { Footer } from '@thg-harveynash/hyper-shared-components';

const DefaultFooter = () => {

    return (
        <Footer start={<span>Version: 2022.1.2</span>} end={<span>© THG 2022</span>} />

    );
};
export default DefaultFooter;