import moment from "moment";
import { FormInput } from "@thg-harveynash/hyper-shared-components";
import { ChangeEventHandler, FocusEventHandler } from "react";
import {
  LocaleAccess,
  OrgAccess,
  PermissionsTreeType,
  SiteAccess,
} from "../pages/UPS/UserManagement/UserPermission/EditUserPermission";
import {
  Role,
  Permission,
  UserPermissionPayload,
  RoleToAssignSetupDto,
  ApplicationRoleDto,
} from "../models/types";
import {
  LocaleI,
  OrgI,
  SiteI,
} from "../pages/UPS/UserManagement/RolesToAssign/AddRolesToAssign/AddRolesToAssign";
import { remove } from "lodash";
import { toast } from "react-toastify";
import { NOT_AUTHORIZED } from "../constants";

export const getPerPageTable = () => {
  const perPageTable = localStorage.getItem("perPageTable") || 10;
  return Number(perPageTable);
};

export const getSelectOptionAndArrayStringOption = (
  options: string | (string | null)[] | null
) => {
  if (!options) {
    return { selectOption: [], arrayStringOption: [] };
  }
  let newSelectOption: any[] = [];
  let newArrayStringOption: any[] = [];
  if (typeof options === "string") {
    newSelectOption = [{ value: options, label: options }];
    newArrayStringOption = [options];
  } else {
    newSelectOption = options?.map((status: any) => {
      return { value: status, label: status };
    });
    newArrayStringOption = options;
  }

  return {
    selectOption: newSelectOption,
    arrayStringOption: newArrayStringOption,
  };
};

export const convertTimeUTC = (
  time: string | undefined,
  formatType: string
) => {
  if (!time) return "";
  return moment(time).utc().format(formatType);
};

export const getToken = () => {
  return localStorage.getItem("token") || "";
};

export const getOptionItem = (data: any) => {
  let itemArray: { value: any; label: any }[] = [];
  if (data && data?.data?.items?.length) {
    data?.data.items?.forEach((item: any) => {
      itemArray.push({
        value: item.id,
        label: item.name,
      });
    });
  }
  return itemArray;
};

export const getSortField = (
  sortField: string,
  sortOrder: number,
  defaultSort: string
): string => {
  if (sortField === defaultSort) return defaultSort;
  return `${sortField}:${sortOrder === 1 ? "asc" : "desc"}`;
};

export const intersection = (a: readonly any[], b: readonly any[]) => {
  return a.filter((value) => b.indexOf(value) !== -1);
};

export const not = (a: readonly any[], b: readonly any[]) => {
  return a.filter((value) => !b?.some((bValue) => bValue?.id === value?.id));
};

export const union = (a: readonly any[], b: readonly any[]) => {
  return [...a, ...not(b, a)]; // NOSONAR
};

export const findItem = (data: any[], id?: string) => {
  if (!id) {
    return undefined;
  }
  let item = data?.find(function (i: any) {
    return i.id === id;
  });
  if (!item) {
    return undefined;
  }
  return item;
};

export const findOrgCount = (
  data: PermissionsTreeType[],
  appId: string,
  orgId: string
) => {
  let dataApp = findItem(data, appId);
  if (!dataApp) {
    return undefined;
  }
  let org = findItem(dataApp.organizations, orgId);
  if (!org) {
    return undefined;
  }
  return org?.sites?.length;
};

export const findSiteCount = (
  data: PermissionsTreeType[],
  appId: string,
  orgId: string,
  siteId: string
) => {
  let dataApp = findItem(data, appId);
  if (!dataApp) {
    return undefined;
  }
  let org = findItem(dataApp.organizations, orgId);
  if (!org) {
    return undefined;
  }
  let site = findItem(org.sites, siteId);
  if (!site) {
    return undefined;
  }
  if (site?.locales?.length === 0) return 1;
  return site?.locales?.length;
};

export const findRoleToAssignCount = (
  data: OrgI[],
  type: string,
  orgId?: string,
  siteId?: string,
  localeId?: string
) => { //NOSONAR
  let orgFind = findItem(data, orgId);
  switch (type) {
    case "findOrgCount":
      if (orgFind) {
        return orgFind?.sites?.length;
      }
      return undefined;
    case "findSiteCount":
      if (orgFind) {
        let siteFind = findItem(orgFind.sites, siteId);
        if (siteFind) {
          if (siteFind?.roles?.length > 0) {
            return 1;
          } else {
            return siteFind?.locales?.length;
          }
        }
        return undefined;
      }
      return undefined;
    case "findLocaleCount":
      if (orgFind) {
        let siteFind = findItem(orgFind.sites, siteId);
        if (siteFind) {
          let locale = findItem(siteFind.locales, localeId);
          if (locale) {
            return locale?.roles?.length;
          }
          return undefined;
        }
        return undefined;
      }
      return undefined;
    default:
      break;
  }
};

export const filterByName = (items: any, value: string) => {
  return items?.filter((o: any) =>
    String(o?.name).toLowerCase().includes(value.toLowerCase().trim())
  );
};

export const flatUserPermissionData = (userPermissionData: any) => {
  let appFlat: UserPermissionPayload[] = [];
  userPermissionData?.applications?.forEach((app: any) => { // NOSONAR
    let rolesFlat: Role[] = [];
    let permissionFlat: Permission[] = [];
    app?.organizations?.forEach((org: any) => {
      org?.sites?.forEach((site: any) => {
        if (site?.roles?.length > 0) {
          site?.roles?.forEach((role: any) => {
            const roleItemSite: Role = {
              roleId: role?.id,
              siteId: site?.id,
              organizationId: org?.id,
            };
            rolesFlat.push(roleItemSite);
          });
        }
        if (site?.locales?.length > 0) {
          site?.locales?.forEach((locale: any) => {
            if (locale?.roles?.length > 0) {
              locale?.roles?.forEach((roleLocale: any) => {
                const roleItemLocale: Role = {
                  roleId: roleLocale?.id,
                  siteId: site?.id,
                  organizationId: org?.id,
                  localeId: locale?.id,
                };
                rolesFlat.push(roleItemLocale);
              });
            }
          });
        }
        if (site?.permissions?.length > 0) {
          site?.permissions?.forEach((permissionSite: any) => {
            const permissionItemSite: Permission = {
              permissionId: permissionSite?.id,
              siteId: site?.id,
              organizationId: org?.id,
            };
            permissionFlat.push(permissionItemSite);
          });
        }
        if (site?.locales?.length > 0) {
          site?.locales?.forEach((localeSite: any) => {
            if (localeSite?.permissions?.length > 0) {
              localeSite?.permissions?.forEach((permissionLocaleSite: any) => {
                const permissionItemLocaleSite: Permission = {
                  permissionId: permissionLocaleSite?.id,
                  siteId: site?.id,
                  organizationId: org?.id,
                  localeId: localeSite?.id,
                };
                permissionFlat.push(permissionItemLocaleSite);
              });
            }
          });
        }
      });
    });
    const appItem: UserPermissionPayload = {
      action: "SETUP_USER_PERMISSIONS",
      payload: {
        userId: userPermissionData?.id,
        applicationCode: app?.code,
        roles: [...rolesFlat],
        permissions: [...permissionFlat],
      },
    };
    appFlat.push(appItem);
  });
  return appFlat;
};

export const getPermissionList = () => {
  return JSON.parse(localStorage.getItem("permissionList") || "[]") || [];
};

export const renderFieldInputDisable = (
  name: string,
  value: any,
  errors: any
) => {
  return <FormInput value={value} disabled name={name} errors={errors} />;
};

export const removeItemInData = (data: any[], idRemove: string) => {
  remove(data, function (item: any) {
    return item.id === idRemove;
  });
};

export const renderFormInput = (
  onBlur: FocusEventHandler<HTMLInputElement>,
  onChange: ChangeEventHandler<HTMLInputElement>,
  value: any,
  name: string,
  errors: any,
  maxLength: number
) => {
  return (
    <FormInput
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      name={name}
      errors={errors}
      maxLength={maxLength}
    />
  );
};

export const flatRolesToAssignData = (rolesToAssignData: OrgI[]) => {
  let flatData: RoleToAssignSetupDto[] = [];
  rolesToAssignData?.forEach((org: OrgI) => {
    org?.sites?.forEach((site: SiteI) => {
      if (site?.roles && site?.roles?.length > 0) {
        site?.roles?.forEach((role: ApplicationRoleDto) => {
          flatData.push({
            assignId: role.id || "",
            siteId: site.id,
            organizationId: org.id,
          });
        });
      } else {
        site?.locales?.forEach((locale: LocaleI) => {
          if (locale?.roles && locale?.roles?.length > 0) {
            locale?.roles?.forEach((role: ApplicationRoleDto) => {
              flatData.push({
                assignId: role.id || "",
                localeId: locale.id,
                siteId: site.id,
                organizationId: org.id,
              });
            });
          }
        });
      }
    });
  });
  return flatData;
};

const checkLocaleHasRoleOrPermission = (locale: LocaleAccess) => {
  return locale?.roles?.length || locale?.permissions?.length;
};

const checkSiteHasRoleOrPermission = (site: SiteAccess) => {
  if (site?.roles?.length || site?.permissions?.length) {
    return true;
  }
  if (site?.locales?.some(checkLocaleHasRoleOrPermission)) return true;
  return false;
};

export const checkOrgHasRoleOrPermission = (org: OrgAccess) => {
  return org?.sites?.some(checkSiteHasRoleOrPermission);
};

export const checkAppHasRoleOrPermission = (app: PermissionsTreeType) => {
  return app?.organizations?.some(checkOrgHasRoleOrPermission);
};

export const showErrorAuthor = (error: any) => {
  if (error?.response?.status === 403) {
    toast.error(NOT_AUTHORIZED);
    return;
  }
  toast.error(`${error?.response?.data?.message}`);
};

export const compareTwoStringArray = (array1: string[], array2: string[]) => {
  let isEqual = true;
  if (array1.length !== array2.length) return false;
  const array2Sorted = array2.sort();
  array1.sort().every(function (value, index) {
    if (value !== array2Sorted[index]) {
      isEqual = false;
    }
    return isEqual;
  });
  return isEqual;
};
