import { AxiosResponse } from "axios";
import {
  MutationFunction,
  MutationOptions,
  QueryFunction,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import request from "../request";
import { QueryOptions } from "../types";
import {
  PermissionPageDto,
  ApplicationPermissionDto as Permissions,
  GetApplicationPermissionsParams as PermissionParams,
} from "../../models/types";

type PermissionsResponse = {
  get: AxiosResponse<PermissionPageDto>;
  getList: AxiosResponse<Permissions[]>;
  getOne: AxiosResponse<Permissions>;
  getGeneric: AxiosResponse<Permissions[]>;
  create: AxiosResponse<Permissions>;
  delete: AxiosResponse<Permissions>;
  update: AxiosResponse<string, Permissions>;
  replace: AxiosResponse<string, Permissions>;
};

// for get action
type PermissionsQueryKey = {
  get: ["getPermissions", PermissionParams];
  getList: ["getPermissionsList", string, PermissionParams];
  getOne: ["getPermissionsDetail", string, string];
  getGeneric: ["getGenericPermission", string];
  getPermissionViaRole: ["getPermissionViaRole", string, string];
};

// for mutation action
type PermissionsVariables = {
  create: Permissions;
  delete: number;
  update: {
    data: Permissions;
    appId: string;
  };
  replace: {
    data: string[];
    appId: string;
    roleId: string;
  };
};

type PermissionsAPI = {
  get: QueryFunction<PermissionsResponse["get"], PermissionsQueryKey["get"]>;
  getList: QueryFunction<
    PermissionsResponse["getList"],
    PermissionsQueryKey["getList"]
    >;
  getOne: QueryFunction<
    PermissionsResponse["getOne"],
    PermissionsQueryKey["getOne"]
  >;
  getGeneric: QueryFunction<
    PermissionsResponse["getGeneric"],
    PermissionsQueryKey["getGeneric"]
  >;
  getPermissionViaRole: QueryFunction<
    PermissionsResponse["getGeneric"],
    PermissionsQueryKey["getPermissionViaRole"]
  >;
  create: MutationFunction<
    PermissionsResponse["create"],
    PermissionsVariables["create"]
  >;
  delete: MutationFunction<
    PermissionsResponse["delete"],
    PermissionsVariables["delete"]
  >;
  update: MutationFunction<
    PermissionsResponse["update"],
    PermissionsVariables["update"]
  >;
  replace: MutationFunction<
    PermissionsResponse["replace"],
    PermissionsVariables["replace"]
    >;
};

const PREFIX = "applicationPermissions";

const permissions: PermissionsAPI = {
  get: ({ queryKey: [, params] }) => request.get(`${PREFIX}`, { params }),
  getList: ({ queryKey: [, appId, params] }) =>
    request.get(`/applications/${appId}/permissions`, { params }),
  getOne: ({ queryKey: [, appId, permissionId] }) =>
    request.get(`/applications/${appId}/permissions/${permissionId}`),
  getGeneric: ({ queryKey: [, appId] }) =>
    request.get(`/applications/${appId}/permissions?isGeneric=true`),
  getPermissionViaRole: ({ queryKey: [, appId, roleId] }) =>
    request.get(`/applications/${appId}/roles/${roleId}/permissions`),
  create: (data) => request.post(`${PREFIX}`, data),
  delete: (id) => request.delete(`${PREFIX}/${id}`),
  update: (data) =>
    request.put(
      `/applications/${data.appId}/permissions/${data.data.id}`,
      data.data
    ),
  replace: (data) =>
    request.put(
      `/applications/${data.appId}/roles/${data.roleId}/permissions`,
      data.data
    ),
};

export const useGetPermissionsQuery = (
  params: PermissionParams,
  options?: QueryOptions<PermissionsResponse["get"], PermissionsQueryKey["get"]>
) => useQuery(["getPermissions", params], permissions.get, options);

export const useGetPermissionsListQuery = (
  appId: string,
  params: PermissionParams,
  options?: QueryOptions<PermissionsResponse["getList"], PermissionsQueryKey["getList"]>
) => useQuery(["getPermissionsList", appId, params], permissions.getList, options);

export const useGetPermissionsDetailQuery = (
  appId: string,
  permissionId: string,
  options?: QueryOptions<
    PermissionsResponse["getOne"],
    PermissionsQueryKey["getOne"]
  >
) =>
  useQuery(
    ["getPermissionsDetail", appId, permissionId],
    permissions.getOne,
    options
  );

export const useGetGenericPermissionsQuery = (
  appId: string,
  options?: QueryOptions<
    PermissionsResponse["getGeneric"],
    PermissionsQueryKey["getGeneric"]
  >
) => useQuery(["getGenericPermission", appId], permissions.getGeneric, options);

export const useGetPermissionsViaRoleQuery = (
  appId: string,
  roleId: string,
  options?: QueryOptions<
    PermissionsResponse["getGeneric"],
    PermissionsQueryKey["getPermissionViaRole"]
  >
) =>
  useQuery(
    ["getPermissionViaRole", appId, roleId],
    permissions.getPermissionViaRole,
    options
  );

export const useCreatePermissionsMutation = (
  options?: MutationOptions<
    PermissionsResponse["create"],
    unknown,
    PermissionsVariables["create"]
  >
) => useMutation(["createPermissions"], permissions.create, options);

export const useDeletePermissionsMutation = (
  options?: MutationOptions<
    PermissionsResponse["delete"],
    unknown,
    PermissionsVariables["delete"]
  >
) => useMutation(["deletePermissions"], permissions.delete, options);

export const useUpdatePermissionMutation = (
  options?: MutationOptions<
    PermissionsResponse["update"],
    unknown,
    PermissionsVariables["update"]
  >
) => useMutation(["createModule"], permissions.update, options);

export const useReplacePermissionMutation = (
  options?: MutationOptions<
    PermissionsResponse["replace"],
    unknown,
    PermissionsVariables["replace"]
    >
) => useMutation(["replacePermissions"], permissions.replace, options);
