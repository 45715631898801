import React, { useState, useContext, useLayoutEffect, useEffect } from "react";
import Keycloak, { KeycloakInstance } from "keycloak-js";
import jwt_decode from "jwt-decode";
import Spinner from "@thg-harveynash/cp-hyper-react-package-ui/dist/components/Spinner";
import { GlobalConfig } from "../global";

type AuthProps = React.PropsWithChildren<{}>;

interface InitState {
  auth: any;
  keyCloak: any;
}

const initialState: InitState = {
  auth: {},
  keyCloak: null,
};

const AuthContext = React.createContext(initialState);

function AuthProvider({ children }: AuthProps) {
  const [state, setState] = useState({
    ...initialState,
  });
  const [loading, setLoading] = useState(true);
  const [keycloakValue, setKeycloakValue] = useState<KeycloakInstance | null>(
    null
  );

  const afterLoginKeycloak = async (keycloak: KeycloakInstance) => {
    const { token = "" } = keycloak;
    const decoded = jwt_decode(token);
    localStorage.setItem("token", token);
    setState({ ...state, keyCloak: keycloak, auth: decoded });
    setKeycloakValue(keycloak);
    setLoading(false);
  };

  const updateKeycloak = () => {
    keycloakValue
      ?.updateToken(300)
      .then(() => {
        afterLoginKeycloak(keycloakValue);
      })
      .catch(() => {
        keycloakValue?.logout();
      });
  };

  useEffect(() => {
    if (keycloakValue) {
      let interval: any;
      interval = setInterval(() => {
        updateKeycloak();
      }, 270000);
      return () => {
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keycloakValue]);

  useLayoutEffect(() => {
    const keycloak: KeycloakInstance = new (Keycloak as any)({
      url: GlobalConfig().config.REACT_APP_URL_KEYCLOAK,
      realm: GlobalConfig().config.REACT_APP_REALM,
      clientId: GlobalConfig().config.REACT_APP_CLIENT_ID,
    });

    keycloak
      .init({
        onLoad: "login-required",
        checkLoginIframe: true,
        checkLoginIframeInterval: 1,
        pkceMethod: "S256",
        scope: "profile openid",
      })
      .then(() => {
        if (keycloak?.token) {
          afterLoginKeycloak(keycloak);
          localStorage.setItem("token", keycloak?.token);
        }
      })
      .catch(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider value={state}>
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <Spinner />
        </div>
      ) : (
        <>{children}</>
      )}
    </AuthContext.Provider>
  );
}

const useAuthInfo = () => {
  const { auth } = useContext(AuthContext);
  return auth;
};

const useKeyCloak = () => {
  const { keyCloak } = useContext(AuthContext);
  return keyCloak;
};

export { AuthProvider, useAuthInfo, useKeyCloak };
export default AuthContext;
