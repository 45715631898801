import { AxiosResponse } from "axios";
import {
  MutationFunction,
  MutationOptions,
  QueryFunction,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import request from "../request";
import { QueryOptions } from "../types";
import {
  ApplicationDto as Application,
  ApplicationPageDto,
  GetApplicationsParams as ApplicationParams,
} from "../../models/types";

type ApplicationResponse = {
  get: AxiosResponse<ApplicationPageDto>;
  getOne: AxiosResponse<Application>;
  create: AxiosResponse<Application>;
  delete: AxiosResponse<Application>;
  update: AxiosResponse<string, Application>;
};

// for get action
type ApplicationQueryKey = {
  get: ["getApplication", ApplicationParams];
  getOne: ["getApplicationDetail", string];
};

// for mutation action
type ApplicationVariables = {
  create: Application;
  delete: number;
  update: { data: Application; id: string };
};

type ApplicationAPI = {
  get: QueryFunction<ApplicationResponse["get"], ApplicationQueryKey["get"]>;
  getOne: QueryFunction<
    ApplicationResponse["getOne"],
    ApplicationQueryKey["getOne"]
  >;
  create: MutationFunction<
    ApplicationResponse["create"],
    ApplicationVariables["create"]
  >;
  delete: MutationFunction<
    ApplicationResponse["delete"],
    ApplicationVariables["delete"]
  >;
  update: MutationFunction<
    ApplicationResponse["update"],
    ApplicationVariables["update"]
  >;
};

export const PREFIX = "applications";

const application: ApplicationAPI = {
  get: ({ queryKey: [, params] }) => request.get(`${PREFIX}`, { params }),
  getOne: ({ queryKey: [, id] }) => request.get(`${PREFIX}/${id}`),
  create: (data) => request.post(`${PREFIX}`, data),
  delete: (id) => request.delete(`${PREFIX}/${id}`),
  update: (data) => request.put(`${PREFIX}/${data.id}`, data.data),
};

export const useGetApplicationsQuery = (
  params: ApplicationParams,
  options?: QueryOptions<
    ApplicationResponse["get"],
    ApplicationQueryKey["get"]
  >
) => useQuery(["getApplication", params], application.get, options);

export const useGetApplicationDetailQuery = (
  id: string,
  options?: QueryOptions<
    ApplicationResponse["getOne"],
    ApplicationQueryKey["getOne"]
  >
) => useQuery(["getApplicationDetail", id], application.getOne, options);

export const useCreateApplicationMutation = (
  options?: MutationOptions<
    ApplicationResponse["create"],
    unknown,
    ApplicationVariables["create"]
  >
) => useMutation(["createApplication"], application.create, options);

export const useDeleteApplicationMutation = (
  options?: MutationOptions<
    ApplicationResponse["delete"],
    unknown,
    ApplicationVariables["delete"]
  >
) => useMutation(["deleteApplication"], application.delete, options);

export const useUpdateApplicationMutation = (
  options?: MutationOptions<
  ApplicationResponse['update'],
    unknown,
    ApplicationVariables['update']
  >,
) => useMutation(['createOrganization'], application.update, options)