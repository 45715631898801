import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import QueryString from "qs";
import { getToken } from "../utils";
import { GlobalConfig } from "../global";

let request: AxiosInstance = axios.create();

request.interceptors.request.use(async (config: AxiosRequestConfig) => {
  config.paramsSerializer = {
    serialize: (params: any) => {
      return QueryString.stringify(params, { arrayFormat: "repeat" });
    },
  };
  const token = getToken();
  config.headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  config.baseURL = GlobalConfig().config?.REACT_APP_UPS_BASE_URL;
  return config;
});

export default request;
