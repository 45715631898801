import React, { useState } from 'react';
import { Button } from '@thg-harveynash/hyper-shared-components';
import { RightSidebar } from '@thg-harveynash/cp-hyper-ui-comps';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className="help-bottom">
            <p className="mb-2">Was this article useful?</p>
            <div className="row gx-2">
                <div className="col-6">
                    <Button
                        iconPrefix={{ name: 'mdi mdi-thumb-up-outline', size: 14 }}
                        name="Thanks"
                        className="w-100"
                        size="sm"
                        variant="outline-primary"
                    />
                </div>
                <div className="col-6">
                    <Button
                        iconPrefix={{ name: 'mdi mdi-thumb-down-outline', size: 14 }}
                        name="Not really"
                        className="w-100"
                        size="sm"
                        variant="outline-primary"
                    />
                </div>
            </div>
        </div>
    );
};

const HelpCenter = () => {
    const [isShowHelpCenter, setIsShowHelpCenter] = useState(false);
    return (
        <RightSidebar
            title="Help Center"
            showRightSidebar={isShowHelpCenter}
            setShowRightSidebar={(value) => setIsShowHelpCenter(value)}
            footer={<Footer />}>
            <div className="flex-1 help-content">
                <div className="app-search right">
                    <form>
                        <div className="mb-2 position-relative">
                            <input type="text" className="form-control border" placeholder="Search" />
                            <span className="mdi mdi-magnify search-icon text-primary"></span>
                        </div>
                    </form>
                </div>
                <div className="flex-1">
                    <Link className="fw-bold text-decoration-underline" to="#">
                        All help topics
                    </Link>
                    <div className="help_label py-2">
                        <span>Demo Apparel</span>
                        <Link to="#">
                            <i className="mdi mdi-open-in-new icon_bold"></i>
                        </Link>
                    </div>
                </div>
            </div>
        </RightSidebar>
    );
};

export default HelpCenter;
