import React from "react";
import { Icon } from "@thg-harveynash/hyper-shared-components";
import { PERMISSIONS } from "../constants";

export const PROFILE_MENU_ITEMS = [
  {
    label: "My Account",
    icon: "mdi mdi-account-circle",
    onClick: () => {},
  },
  {
    label: "Settings",
    icon: "mdi mdi-account-edit",
    onClick: () => {},
  },
  {
    label: "Support",
    icon: "mdi mdi-lifebuoy",
    onClick: () => {},
  },
  {
    label: "Lock Screen",
    icon: "mdi mdi-lock-outline",
    onClick: () => {},
  },
  {
    label: "Logout",
    icon: "mdi mdi-logout",
    onClick: () => {},
  },
];

export type MenuItem = {
  key: string;
  label: string;
  isTitle: boolean;
  iconElement?: JSX.Element;
  permissions?: string[];
  parentKey?: string;
  url?: string;
  children?: MenuItem[];
};

export const MENU_ITEMS: MenuItem[] = [
  {
    key: "application-setup",
    label: "Application Setup",
    isTitle: false,
    iconElement: (
      <Icon name="all_application" className="mdi mdi-cog" size={20} />
    ),
    permissions: [
      PERMISSIONS.view_application_list,
      PERMISSIONS.view_module_list,
      PERMISSIONS.view_role_list,
      PERMISSIONS.view_permission_list,
      PERMISSIONS.edit_application,
      PERMISSIONS.add_module,
      PERMISSIONS.add_role,
      PERMISSIONS.view_roles_to_assign_list,
      PERMISSIONS.add_permission,
      PERMISSIONS.edit_permission,
      PERMISSIONS.add_roles_to_assign,
      PERMISSIONS.edit_roles_to_assign,
      PERMISSIONS.edit_role_permissions,
      PERMISSIONS.view_role_permissions,
      PERMISSIONS.view_site_list,
      PERMISSIONS.edit_site,
      PERMISSIONS.view_locale_list,
      PERMISSIONS.add_locale,
      PERMISSIONS.edit_user_permission,
    ],
    children: [
      {
        key: "applications",
        parentKey: "application-setup",
        label: "Applications",
        isTitle: false,
        url: "ups/applications",
        permissions: [
          PERMISSIONS.view_application_list,
          PERMISSIONS.view_module_list,
          PERMISSIONS.edit_application,
          PERMISSIONS.add_module,
          PERMISSIONS.view_role_list,
          PERMISSIONS.add_role,
          PERMISSIONS.view_roles_to_assign_list,
          PERMISSIONS.view_permission_list,
          PERMISSIONS.add_permission,
          PERMISSIONS.edit_permission,
          PERMISSIONS.edit_user_permission,
          PERMISSIONS.add_roles_to_assign,
          PERMISSIONS.edit_roles_to_assign,
          PERMISSIONS.view_locale_list,
          PERMISSIONS.add_locale,
        ],
      },
      {
        key: "modules",
        parentKey: "application-setup",
        label: "Modules",
        isTitle: false,
        url: "ups/modules",
        permissions: [
          PERMISSIONS.view_module_list,
          PERMISSIONS.add_permission,
          PERMISSIONS.view_permission_list,
          PERMISSIONS.edit_permission,
        ],
      },
      {
        key: "roles",
        parentKey: "application-setup",
        label: "Roles",
        isTitle: false,
        url: "ups/roles",
        permissions: [
          PERMISSIONS.view_role_list,
          PERMISSIONS.add_roles_to_assign,
          PERMISSIONS.edit_roles_to_assign,
        ],
      },
      {
        key: "permissions",
        parentKey: "application-setup",
        label: "Permissions",
        isTitle: false,
        url: "ups/permissions",
        permissions: [
          PERMISSIONS.view_permission_list,
          PERMISSIONS.edit_role_permissions,
          PERMISSIONS.view_role_permissions,
        ],
      },
    ],
  },
  {
    key: "organization-setup",
    label: "Organization Setup",
    isTitle: false,
    iconElement: (
      <Icon name="all_organization" className="mdi mdi-bag-checked" size={20} />
    ),
    permissions: [
      PERMISSIONS.view_organization_list,
      PERMISSIONS.add_roles_to_assign,
      PERMISSIONS.edit_roles_to_assign,
      PERMISSIONS.view_site_list,
      PERMISSIONS.edit_site,
      PERMISSIONS.view_locale_list,
      PERMISSIONS.add_locale,
      PERMISSIONS.edit_user_permission,
    ],
    children: [
      {
        key: "organizations",
        parentKey: "organization-setup",
        label: "Organizations",
        isTitle: false,
        url: "ups/organizations",
        permissions: [
          PERMISSIONS.view_organization_list,
          PERMISSIONS.add_roles_to_assign,
          PERMISSIONS.edit_roles_to_assign,
          PERMISSIONS.view_site_list,
          PERMISSIONS.edit_site,
          PERMISSIONS.view_locale_list,
          PERMISSIONS.add_locale,
          PERMISSIONS.edit_user_permission,
        ],
      },
      {
        key: "sites",
        parentKey: "organization-setup",
        label: "Sites",
        isTitle: false,
        url: "ups/sites",
        permissions: [
          PERMISSIONS.view_site_list,
          PERMISSIONS.add_roles_to_assign,
          PERMISSIONS.edit_roles_to_assign,
          PERMISSIONS.view_locale_list,
          PERMISSIONS.add_locale,
          PERMISSIONS.edit_user_permission,
        ],
      },
      {
        key: "locales",
        parentKey: "organization-setup",
        label: "Locales",
        isTitle: false,
        url: "ups/locales",
        permissions: [
          PERMISSIONS.view_locale_list,
          PERMISSIONS.add_roles_to_assign,
          PERMISSIONS.edit_roles_to_assign,
          PERMISSIONS.edit_user_permission,
        ],
      },
    ],
  },
  {
    key: "user-management",
    label: "User Management",
    isTitle: false,
    iconElement: (
      <Icon
        name="all_organization"
        className="mdi mdi-card-account-details"
        size={20}
      />
    ),
    permissions: [
      PERMISSIONS.view_user_list,
      PERMISSIONS.view_user_permissions_list,
      PERMISSIONS.view_roles_to_assign_list,
    ],
    children: [
      {
        key: "users",
        parentKey: "user-management",
        label: "Users",
        isTitle: false,
        url: "ups/users",
        permissions: [
          PERMISSIONS.view_user_list,
          PERMISSIONS.view_user_permissions_list,
        ],
      },
      {
        key: "user-permissions",
        parentKey: "user-management",
        label: "User Permissions",
        isTitle: false,
        url: "ups/user-permissions",
        permissions: [PERMISSIONS.view_user_permissions_list],
      },
      {
        key: "roles-to-assign",
        parentKey: "user-management",
        label: "Roles to Assign",
        isTitle: false,
        url: "ups/roles-to-assign",
        permissions: [PERMISSIONS.view_roles_to_assign_list],
      },
    ],
  },
];

export const getMenuItemByPermission = (permissionList: string[]) => {
  const childrenItem = MENU_ITEMS?.filter((menuItem: MenuItem) =>
    menuItem?.permissions?.some((permissionItem: string) =>
      permissionList?.includes(permissionItem)
    )
  );
  return childrenItem?.map((cItem: MenuItem) => {
    if (cItem?.children) {
      cItem.children = cItem.children?.filter((cPermission: MenuItem) =>
        cPermission?.permissions?.some((per: string) =>
          permissionList?.includes(per)
        )
      );
    }
    return cItem;
  });
};
