import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.scss';
import { Layout } from './components/Layout';
import Spinner from './components/Spinner';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@thg-harveynash/hyper-shared-components/build/scss/Saas.scss";

const UPS = React.lazy(() => import("./pages/UPS"));

function App() {
  return (
    <div className="App">
      <Layout>
        <Routes>
          <Route
            path="ups/*"
            element={
              <Suspense fallback={<Spinner />}>
                <UPS />
              </Suspense>
            }
          />
        </Routes>
        <ToastContainer />
      </Layout>
    </div>
  );
}

export default App;
