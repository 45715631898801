export const NOTIFICATIONS = {
    notifications: [
        {
            messages: [
                {
                    id: 1,
                    title: 'Datacorp',
                    subText: 'Caleb Flakelar commented on Admin',
                    time: '1 minute ago',
                    icon: 'mdi mdi-comment-account-outline',
                    variant: 'primary',
                    isRead: false,
                },
            ],
        },
        {
            messages: [
                {
                    id: 2,
                    title: 'Admin',
                    subText: 'New user registered.',
                    time: '1 hours ago',
                    icon: 'mdi mdi-account-plus',
                    variant: 'info',
                    isRead: true,
                },
            ],
        },
    ],
    handleClearAllNotifications: () => { },
    handleClickMessage: () => { },
    handleViewAllNotifications: () => { },
};
