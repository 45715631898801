import enFlag from '../assets/images/flags/us.jpg';
import germanyFlag from '../assets/images/flags/germany.jpg';
import italyFlag from '../assets/images/flags/italy.jpg';
import spainFlag from '../assets/images/flags/spain.jpg';
import russiaFlag from '../assets/images/flags/russia.jpg';

export const rawLanguages = [
  {
    name: 'English',
    flag: enFlag,
    value: 'en',
  },
  {
    name: 'German',
    flag: germanyFlag,
    value: 'de',
  },
  {
    name: 'Italian',
    flag: italyFlag,
    value: 'it',
  },
  {
    name: 'Spanish',
    flag: spainFlag,
    value: 'es',
  },
  {
    name: 'Russian',
    flag: russiaFlag,
    value: 'ru',
  },
];