export const TITLE_APPLICATION_TABLE = {
  APPLICATION_CODE: "code",
  NAME: "name",
  STATUS: "activeStatus",
};

export const APPLICATIONS_COLUMNS = [
  {
    field: TITLE_APPLICATION_TABLE.APPLICATION_CODE,
    header: "Application Code",
  },
  { field: TITLE_APPLICATION_TABLE.NAME, header: "Application Name" },
  { field: TITLE_APPLICATION_TABLE.STATUS, header: "Status" },
];

export const APPLICATION_STATUS = [
  { value: "ACTIVE", label: "Active" },
  { value: "INACTIVE", label: "Inactive" },
];

export const LIMIT_GET_LIST_DATA = 1000;

export const TITLE_MODULE_TABLE = {
  MODULE_CODE: "code",
  NAME: "name",
  APPLICATION_NAME: "app.name",
  STATUS: "activeStatus",
};

export const DEFAULT_MODULE_SORT = "app.name:asc,code:asc";

export const MODULES_COLUMNS = [
  {
    field: TITLE_MODULE_TABLE.MODULE_CODE,
    header: "Module Code",
  },
  { field: TITLE_MODULE_TABLE.NAME, header: "Module Name" },
  { field: TITLE_MODULE_TABLE.APPLICATION_NAME, header: "Application Name" },
  { field: TITLE_MODULE_TABLE.STATUS, header: "Status" },
];

export const MODULE_STATUS = [
  { value: "ACTIVE", label: "Active" },
  { value: "INACTIVE", label: "Inactive" },
];

export const MODULE_APP_FILTER_LIMIT_PAGE = 25;

export const ROLE_STATUS = [
  { value: "ACTIVE", label: "Active" },
  { value: "INACTIVE", label: "Inactive" },
];

export const TITLE_ROLE_TABLE = {
  ROLE_CODE: "code",
  NAME: "name",
  APPLICATION_NAME: "app.name",
  STATUS: "activeStatus",
};

export const ROLE_COLUMNS = [
  {
    field: TITLE_ROLE_TABLE.ROLE_CODE,
    header: "Role Code",
  },
  { field: TITLE_ROLE_TABLE.NAME, header: "Role Name" },
  { field: TITLE_ROLE_TABLE.APPLICATION_NAME, header: "Application Name" },
  { field: TITLE_ROLE_TABLE.STATUS, header: "Status" },
];

export const DEFAULT_ROLES_SORT = "app.name:asc,code:asc";

export const TITLE_PERMISSIONS_TABLE = {
  PERMISSION_CODE: "code",
  NAME: "name",
  APPLICATION_NAME: "app.name",
  MODULE_NAME: "module.name",
  STATUS: "activeStatus",
};

export const PERMISSIONS_COLUMNS = [
  {
    field: TITLE_PERMISSIONS_TABLE.PERMISSION_CODE,
    header: "Permission Code",
  },
  { field: TITLE_PERMISSIONS_TABLE.NAME, header: "Permission Name" },
  {
    field: TITLE_PERMISSIONS_TABLE.APPLICATION_NAME,
    header: "Application Name",
  },
  { field: TITLE_PERMISSIONS_TABLE.MODULE_NAME, header: "Module Name" },
  { field: TITLE_PERMISSIONS_TABLE.STATUS, header: "Status" },
];

export const PERMISSIONS_STATUS = [
  { value: "ACTIVE", label: "Active" },
  { value: "INACTIVE", label: "Inactive" },
];

export const DEFAULT_PERMISSIONS_SORT = "app.name:asc,module.name:asc,code:asc";

export const TITLE_ORGANIZATIONS_TABLE = {
  ERP_ID: "erpId",
  NAME: "name",
  STATUS: "activeStatus",
};

export const ORGANIZATIONS_COLUMNS = [
  {
    field: TITLE_ORGANIZATIONS_TABLE.ERP_ID,
    header: "ERP ID",
  },
  { field: TITLE_ORGANIZATIONS_TABLE.NAME, header: "Organization Name" },
  { field: TITLE_ORGANIZATIONS_TABLE.STATUS, header: "Status" },
];

export const ORGANIZATIONS_STATUS = [
  { value: "ACTIVE", label: "Active" },
  { value: "INACTIVE", label: "Inactive" },
];

export const CHAR_CODE_LOWER_CASE = {
  FROM: 97,
  TO: 122,
};

export const CHAR_CODE_NUMBER = {
  FROM: 48,
  TO: 57,
};

export const CHAR_CODE_OTHER = [46, 95];

export const KEY_CODE_CONTROL = [8];

export const ERP_ID_MAX_LENGTH = 32;

export const CODE_MAX_LENGTH = 64;

export const NAME_MAX_LENGTH = 64;

export const NAME_MAX_255 = 255;

export const NAME_MAX_100 = 100;

export const MESS_REQUIRE_FIELD = "Enter a value for this field.";

export const CODE_PATTERN = /^([.a-z_0-9])+$/;

export const DEFAULT_SITE_SORT = "organization.name:asc,erpId:asc";

export const TITLE_SITES_TABLE = {
  ERP_ID: "erpId",
  NAME: "name",
  ORGANIZATION_NAME: "organization.name",
  STATUS: "activeStatus",
};

export const SITES_COLUMNS = [
  {
    field: TITLE_SITES_TABLE.ERP_ID,
    header: "ERP ID",
  },
  { field: TITLE_SITES_TABLE.NAME, header: "Site Name" },
  { field: TITLE_SITES_TABLE.ORGANIZATION_NAME, header: "Organization Name" },
  { field: TITLE_SITES_TABLE.STATUS, header: "Status" },
];

export const SITES_STATUS = [
  { value: "ACTIVE", label: "Active" },
  { value: "INACTIVE", label: "Inactive" },
];

export const DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm:ss";

export const DEFAULT_LOCALE_SORT =
  "site.organization.name:asc,site.name:asc,erpId:asc";

export const TITLE_LOCALES_TABLE = {
  ERP_ID: "erpId",
  NAME: "name",
  ORGANIZATION_NAME: "site.organization.name",
  SITE_NAME: "site.name",
  STATUS: "activeStatus",
};

export const LOCALES_COLUMNS = [
  {
    field: TITLE_LOCALES_TABLE.ERP_ID,
    header: "ERP ID",
  },
  { field: TITLE_LOCALES_TABLE.NAME, header: "Locale Name" },
  { field: TITLE_LOCALES_TABLE.ORGANIZATION_NAME, header: "Organization Name" },
  { field: TITLE_LOCALES_TABLE.SITE_NAME, header: "Site Name" },
  { field: TITLE_LOCALES_TABLE.STATUS, header: "Status" },
];

export const LOCALES_STATUS = [
  { value: "ACTIVE", label: "Active" },
  { value: "INACTIVE", label: "Inactive" },
];

export const ADD_ORG_SUCCESS = "New organization has been added successfully!";

export const ADD_ROLE_SUCCESS = "New role has been added successfully!";

export const ADD_MODULE_SUCCESS = "New module has been added successfully!";

export const ADD_USER_SUCCESS = "New user has been added successfully!";

export const ADD_SITE_SUCCESS = "New site has been added successfully!";

export const PREVENT_LEAVE_MESSAGE =
  "<b>Are you sure you want to leave the form?</b><br></br>\n\nAll unsaved data will be lost.";

export const UPDATE_SUCCESS_MESSAGE =
  "Your change has been saved successfully!";

export const EMPTY_DATA_MESSEAGE = "No records found";

export const TITLE_USER_TABLE = {
  EMAIL_ADDRESS: "email",
  NAME: "firstName",
  STATUS: "activeStatus",
  ACTION: "actions",
};

export const USERS_COLUMNS = [
  {
    field: TITLE_USER_TABLE.EMAIL_ADDRESS,
    header: "Email Address",
  },
  { field: TITLE_USER_TABLE.NAME, header: "Name" },
  { field: TITLE_USER_TABLE.STATUS, header: "Status" },
];

export const TITLE_USER_PERMISSION_TABLE = {
  EMAIL_ADDRESS: "email",
  NAME: "firstName",
  STATUS: "activeStatus",
  ASSIGN_ROLES: "totalRoles",
  ASSIGNED_GENERIC_PERMISSION: "totalGenericPermissions",
  ACTION: "actions",
};

export const USERS_PERMISSIONS_COLUMNS = [
  {
    field: TITLE_USER_PERMISSION_TABLE.EMAIL_ADDRESS,
    header: "Email Address",
  },
  { field: TITLE_USER_PERMISSION_TABLE.NAME, header: "Name" },
  { field: TITLE_USER_PERMISSION_TABLE.ASSIGN_ROLES, header: "Assigned roles" },
  {
    field: TITLE_USER_PERMISSION_TABLE.ASSIGNED_GENERIC_PERMISSION,
    header: "Assigned generic permissions",
  },
  {
    field: TITLE_USER_PERMISSION_TABLE.STATUS,
    header: "Status",
  },
  { field: TITLE_USER_PERMISSION_TABLE.ACTION, header: "Actions" },
];

export const USERS_PERMISSIONS_STATUS = [
  { value: "ACTIVE", label: "Active" },
  { value: "INACTIVE", label: "Inactive" },
];


export const TITLE_ROLES_TO_ASSIGN_TABLE = {
  NAME: "name",
  APPLICATION_NAME: 'appName',
  ACTION: "actions"
};

export const ROLES_TO_ASSIGN_COLUMNS = [
  { field: TITLE_ROLES_TO_ASSIGN_TABLE.NAME, header: "Role" },
  { field: TITLE_ROLES_TO_ASSIGN_TABLE.APPLICATION_NAME, header: "Application" },
  { field: TITLE_ROLES_TO_ASSIGN_TABLE.ACTION, header: "Actions" },
];

export const USER_STATUS = [
  { value: "ACTIVE", label: "Active" },
  { value: "INACTIVE", label: "Inactive" },
];

export const WARNING_DEACTIVE_ORG_DES =
  "\nDeactivating the organization will deactivate all sites and locales under the organization as well.";

export const WARNING_DEACTIVE_ORG_TITLE =
  "Are you sure you want to deactivate this organization?";

export const WARNING_DEACTIVE_APP_DES =
  "\nDeactivating the application will deactivate all modules, roles and permissions under the application as well.";

export const WARNING_DEACTIVE_APP_TITLE =
  "Are you sure you want to deactivate this application?";

export const WARNING_DEACTIVE_MODULE_DES =
  "\nDeactivating the module will deactivate all permissions under the module as well.";

export const WARNING_DEACTIVE_MODULE_TITLE =
  "Are you sure you want to deactivate this module?";

export const WARNING_DEACTIVE_SITE_DES =
  "\nDeactivating the site will deactivate all locales under the site as well.";

export const WARNING_DEACTIVE_SITE_TITLE =
  "Are you sure you want to deactivate this site?";

export const APPLICATION_CODE_PLACEHOLDER = "E.g. mms, cp, wcm, ups,…";

export const MODULE_CODE_PLACEHOLDER = "E.g. account_profile, projects …";

export const ROLE_CODE_PLACEHOLDER =
  "E.g. thg_admin, thg_support, client_admin, …";

export const PERMISSION_CODE_PLACEHOLDER =
  "E.g. view_thg_homepage, preview_form_template, …";

export const WARNING_DELETE_PERMISSIONS =
  "Are you sure you want to delete all permissions of";

export const WARNING_DELETE_ROLES_TO_ASSIGN =
  "Are you sure you want to delete roles to assign of";

export const DELETE_USER_PERMISSION_SUCCESS =
  "Permissions of account have been deleted successfully!";

export const DELETE_ROLE_TO_ASSIGN_SUCCESS =
  "The Role to Assign has been deleted successfully!";

export const SUCCESS_STATUS = 200;

export const CURRENT_APPLICATION_CODE = "ups";

export const IMPORT_SUCCESS_MESSAGE = "The file has been imported successfully!"
export const IMPORT_UNSUCCESS_MESSAGE = "The file has been imported unsuccessfully. Please try to import another CSV file!"

export const PERMISSIONS = {
  view_application_list: "view_application_list",
  add_application: "add_application",
  edit_application: "edit_application",
  view_application_details: "view_application_details",
  view_module_list: "view_module_list",
  add_module: "add_module",
  edit_module: "edit_module",
  view_module_details: "view_module_details",
  view_role_list: "view_role_list",
  add_role: "add_role",
  edit_role: "edit_role",
  view_role_details: "view_role_details",
  edit_role_permissions: "edit_role_permissions",
  view_role_permissions: "view_role_permissions",
  view_roles_to_assign_list: "view_roles_to_assign_list",
  add_roles_to_assign: "add_roles_to_assign",
  edit_roles_to_assign: "edit_roles_to_assign",
  view_roles_to_assign_details: "view_roles_to_assign_details",
  view_permission_list: "view_permission_list",
  add_permission: "add_permission",
  edit_permission: "edit_permission",
  view_permission_details: "view_permission_details",
  view_organization_list: "view_organization_list",
  add_organization: "add_organization",
  edit_organization: "edit_organization",
  view_organization_details: "view_organization_details",
  import_by_CSV: "import_by_CSV",
  view_site_list: "view_site_list",
  add_site: "add_site",
  edit_site: "edit_site",
  view_site_details: "view_site_details",
  view_locale_list: "view_locale_list",
  add_locale: "add_locale",
  edit_locale: "edit_locale",
  view_locale_details: "view_locale_details",
  view_user_list: "view_user_list",
  add_user: "add_user",
  edit_user: "edit_user",
  view_user_details: "view_user_details",
  view_user_permissions_list: "view_user_permissions_list",
  edit_user_permission: "edit_user_permission",
  view_user_permission_details: "view_user_permission_details",
  add_user_permission: "add_user_permission",
};

export const NOT_AUTHORIZED = "You are not authorized to view this page."

export const initItemSelect = {
  id: "",
  name: "",
};

export const SAVE_ROLES_TO_ASSIGN_SUCCESS =
  "Roles to assign have been added successfully!";

  export const EDIT_ROLES_TO_ASSIGN_SUCCESS =
    "Roles to assign have been saved successfully!";