import { AxiosResponse } from "axios";
import {
  MutationFunction,
  MutationOptions,
  QueryFunction,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import request from "../request";
import { QueryOptions } from "../types";
import {
  ModuleDto as Module,
  ModulePageDto,
  GetModulesParams as ModulesParams,
} from "../../models/types";
import { PREFIX as PREFIX_ADD_ROLE } from "./applications";

type ModuleResponse = {
  get: AxiosResponse<ModulePageDto>;
  getOne: AxiosResponse<Module>;
  create: AxiosResponse<Module>;
  delete: AxiosResponse<Module>;
  update: AxiosResponse<string, Module>;
};

// for get action
type ModuleQueryKey = {
  get: ["getModules", ModulesParams];
  getOne: ["getModuleDetail", string, string];
};

// for mutation action
type ModuleVariables = {
  create: Module;
  delete: string;
  update: {
    data: { id: string; name: string; code: string; activeStatus: boolean };
    appId: string;
  };
};

type ModuleAPI = {
  get: QueryFunction<ModuleResponse["get"], ModuleQueryKey["get"]>;
  getOne: QueryFunction<ModuleResponse["getOne"], ModuleQueryKey["getOne"]>;
  create: MutationFunction<ModuleResponse["create"], ModuleVariables["create"]>;
  delete: MutationFunction<ModuleResponse["delete"], ModuleVariables["delete"]>;
  update: MutationFunction<ModuleResponse["update"], ModuleVariables["update"]>;
};

const PREFIX = "modules";

const module: ModuleAPI = {
  get: ({ queryKey: [, params] }) => request.get(`${PREFIX}`, { params }),
  getOne: ({ queryKey: [, appId, moduleId] }) =>
    request.get(`/applications/${appId}/modules/${moduleId}`),
  create: ({ app, ...data }) =>
    request.post(`${PREFIX_ADD_ROLE}/${app?.id}/modules`, data),
  delete: (id) => request.delete(`${PREFIX}/${id}`),
  update: (data) =>
    request.put(`/${PREFIX_ADD_ROLE}/${data.appId}/${PREFIX}/${data.data.id}`, data.data),
};

export const useGetModuleQuery = (
  params: ModulesParams,
  options?: QueryOptions<ModuleResponse["get"], ModuleQueryKey["get"]>
) => useQuery(["getModules", params], module.get, options);

export const useGetModuleDetailQuery = (
  appId: string,
  moduleId: string,
  options?: QueryOptions<ModuleResponse["getOne"], ModuleQueryKey["getOne"]>
) => useQuery(["getModuleDetail", appId, moduleId], module.getOne, options);

export const useCreateModuleMutation = (
  options?: MutationOptions<
    ModuleResponse["create"],
    unknown,
    ModuleVariables["create"]
  >
) => useMutation(["createModule"], module.create, options);

export const useDeleteModuleMutation = (
  options?: MutationOptions<
    ModuleResponse["delete"],
    unknown,
    ModuleVariables["delete"]
  >
) => useMutation(["deleteModule"], module.delete, options);

export const useUpdateModuleMutation = (
  options?: MutationOptions<
    ModuleResponse["update"],
    unknown,
    ModuleVariables["update"]
  >
) => useMutation(["createModule"], module.update, options);
