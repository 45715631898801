// import { SiteDto } from './../../models/types';
import { AxiosResponse } from 'axios'
import {
  MutationFunction,
  MutationOptions,
  QueryFunction,
  useMutation,
  useQuery,
} from '@tanstack/react-query'
import request from '../request'
import { QueryOptions } from '../types'
import { SiteDto as Site,GetApplicationsParams as ApplicationsParams, SitePageDto } from '../../models/types'

type SiteResponse = {
  get: AxiosResponse<SitePageDto>;
  getOne: AxiosResponse<Site>;
  create: AxiosResponse<Site>;
  delete: AxiosResponse<Site>;
  update: AxiosResponse<Site>;
};

// for get action
type SiteQueryKey = {
  get: ["getSites", ApplicationsParams];
  getOne: ["getSiteDetail", string];
  update: ["update", ApplicationsParams];
};

// for mutation action
type SiteVariables = {
  create: Site;
  delete: number;
  update: Site;
};

type SiteAPI = {
  get: QueryFunction<SiteResponse["get"], SiteQueryKey["get"]>;
  getOne: QueryFunction<SiteResponse["getOne"], SiteQueryKey["getOne"]>;
  create: MutationFunction<SiteResponse["create"], SiteVariables["create"]>;
  delete: MutationFunction<SiteResponse["delete"], SiteVariables["delete"]>;
  update: MutationFunction<SiteResponse["update"], SiteVariables["update"]>;
};

export const PREFIX_SITE = "sites";

const site: SiteAPI = {
  get: ({ queryKey: [, params] }) => request.get(`${PREFIX_SITE}`, { params }),
  getOne: ({ queryKey: [, id] }) => request.get(`${PREFIX_SITE}/${id}`),
  create: (data) =>
    request.post(`organizations/${data?.organization?.id}/${PREFIX_SITE}`, data),
  delete: (id) => request.delete(`${PREFIX_SITE}/${id}`),
  update: (data) => request.put(`${PREFIX_SITE}/${data?.id}`, data),
};

export const useGetSiteQuery = (
  params: ApplicationsParams,
  options?: QueryOptions<SiteResponse["get"], SiteQueryKey["get"]>
) => useQuery(["getSites", params], site.get, options);

export const useGetSiteDetailQuery = (
  id: string,
  options?: QueryOptions<SiteResponse["getOne"], SiteQueryKey["getOne"]>
) => useQuery(["getSiteDetail", id], site.getOne, options);

export const useCreateSiteMutation = (
  options?: MutationOptions<
    SiteResponse["create"],
    unknown,
    SiteVariables["create"]
  >
) => useMutation(["createSite"], site.create, options);

export const useDeleteSiteMutation = (
  options?: MutationOptions<
    SiteResponse["delete"],
    unknown,
    SiteVariables["delete"]
  >
) => useMutation(["deleteSite"], site.delete, options);

export const useUpdateSiteMutation = (
  options?: MutationOptions<
    SiteResponse["update"],
    unknown,
    SiteVariables["update"]
  >
) => useMutation(["updateSite"], site.update, options);