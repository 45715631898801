import { AxiosResponse } from 'axios'
import {
  MutationFunction,
  MutationOptions,
  QueryFunction,
  useMutation,
  useQuery,
} from '@tanstack/react-query'
import request from '../request'
import { QueryOptions } from '../types'
import {
  OrganizationDto as Organization,
  OrganizationPageDto,
  GetApplicationsParams as ApplicationsParams,
  ImportDto,
} from '../../models/types'

type OrganizationResponse = {
  get: AxiosResponse<OrganizationPageDto>
  getOne: AxiosResponse<Organization>
  create: AxiosResponse<Organization>
  delete: AxiosResponse<Organization>
  update: AxiosResponse<string, Organization>
  import: AxiosResponse<ImportDto>
}

// for get action
type OrganizationQueryKey = {
  get: ['getOrganizations', ApplicationsParams]
  getOne: ['getOrganizationDetail', string]
}

// for mutation action
type OrganizationVariables = {
  create: Organization
  delete: number
  update: { data: Organization, id: string }
  import: Blob | string
}

type OrganizationAPI = {
  get: QueryFunction<OrganizationResponse['get'], OrganizationQueryKey['get']>
  getOne: QueryFunction<
    OrganizationResponse['getOne'],
    OrganizationQueryKey['getOne']
  >
  create: MutationFunction<
    OrganizationResponse['create'],
    OrganizationVariables['create']
  >
  delete: MutationFunction<
    OrganizationResponse['delete'],
    OrganizationVariables['delete']
  >
  update: MutationFunction<
    OrganizationResponse['update'],
    OrganizationVariables['update']
  >
  import: MutationFunction<
    OrganizationResponse['import'],
    OrganizationVariables['import']
  >
}

const PREFIX = 'organizations'
const PREFIX_IMPORT = 'import'

const organization: OrganizationAPI = {
  get: ({queryKey: [, params]}) => request.get(`${PREFIX}`, { params }),
  getOne: ({ queryKey: [, id] }) => request.get(`${PREFIX}/${id}`),
  create: data => request.post(`${PREFIX}`, data),
  delete: id => request.delete(`${PREFIX}/${id}`),
  update: data => request.put(`${PREFIX}/${data.id}`, data.data),
  import: data => {
    let formData = new FormData()
    formData.append('file', data)
    return request.post(`${PREFIX_IMPORT}/organizations/csv`, data)
  },
}

export const useGetOrganizationQuery = (
  params: ApplicationsParams,
  options?: QueryOptions<
    OrganizationResponse['get'],
    OrganizationQueryKey['get']
  >
) => useQuery(['getOrganizations', params], organization.get, options)

export const useGetOrganizationDetailQuery = (
  id: string,
  options?: QueryOptions<
    OrganizationResponse['getOne'],
    OrganizationQueryKey['getOne']
  >
) => useQuery(['getOrganizationDetail', id], organization.getOne, options)

export const useCreateOrganizationMutation = (
  options?: MutationOptions<
    OrganizationResponse['create'],
    unknown,
    OrganizationVariables['create']
  >,
) => useMutation(['createOrganization'], organization.create, options)

export const useDeleteOrganizationMutation = (
  options?: MutationOptions<
    OrganizationResponse['delete'],
    unknown,
    OrganizationVariables['delete']
  >,
) => useMutation(['deleteOrganization'], organization.delete, options)

export const useUpdateOrganizationMutation = (
  options?: MutationOptions<
    OrganizationResponse['update'],
    unknown,
    OrganizationVariables['update']
  >,
) => useMutation(['createOrganization'], organization.update, options)

export const useImportOrganizationMutation = (
  options?: MutationOptions<
    OrganizationResponse['import'],
    unknown,
    OrganizationVariables['import']
  >
) => useMutation(['importOrganization'], organization.import, options)
