import React, { useState } from "react";
import { NOTIFICATIONS } from "../../common/notification";
import {
  Header,
  LanguageDropdown,
  NotificationsBar,
} from "@thg-harveynash/hyper-shared-components";
import { ProfileDropdown } from "@thg-harveynash/cp-hyper-ui-comps";
import { rawLanguages } from "../../common/language";
import { useNavigate } from "react-router-dom";
import { useAuthInfo, useKeyCloak } from "../../context/auth-context";
import { useDispatch, useIsFormChanged } from "../../context";
import { useWarningModal } from "../../context/warning-modal-context";
import { Types } from "../../constants/action-type";
import styled from "styled-components";

interface DefaultHeaderProps {
  setLoadingSignOut: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AvatarStyled = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #156ebc;
  color: #fff;
`;

export const NotiWrap = styled.div`
  button {
    display: flex;
    align-items: center;
  }
  .noti-icon-badge {
    top: 7px;
  }
`;

const DefaultHeader = (props: DefaultHeaderProps) => {
  const [language, setLanguage] = useState(rawLanguages[0].value);
  const navigate = useNavigate();
  const auth = useAuthInfo();
  const keyCloak = useKeyCloak();
  const modal = useWarningModal();
  const dispatch = useDispatch();
  const { setLoadingSignOut } = props;
  const isFormChanged = useIsFormChanged();

  const userName = () => {
    if (!auth?.family_name && !auth?.given_name) return auth?.email;
    if (!auth?.given_name) return auth?.family_name;
    if (!auth?.family_name) return auth?.given_name;
    return `${auth?.given_name} ${auth?.family_name}`;
  };

  const avartarName = () => {
    if (!auth?.family_name && !auth?.given_name)
      return auth?.email?.slice(0, 1);
    if (!auth?.family_name) return auth?.given_name?.slice(0, 1);
    if (!auth?.given_name) return auth?.family_name?.slice(0, 1);
    return auth?.given_name?.slice(0, 1) + auth?.family_name?.slice(0, 1);
  };

  const redirectLogoutToHome = () => {
    setLoadingSignOut(true);
    navigate("/");
    localStorage.clear();
    keyCloak?.logout();
  };

  const handleSignOut = () => {
    if (isFormChanged) {
      modal({
        message:
          "<b>Are you sure you want to leave?</b><br></br>\n\nAny changes you have made will not be saved. Please ensure you submit or save your data before leaving the page.",
        changeAccount: () => {
          dispatch({
            type: Types.GET_FORM_DIRTY_STATUS,
            payload: {
              isFormChanged: false,
            },
          });
          setTimeout(() => {
            redirectLogoutToHome();
          }, 10);
        },
      });
    } else {
      dispatch({
        type: Types.GET_FORM_DIRTY_STATUS,
        payload: {
          isFormChanged: false,
        },
      });
      redirectLogoutToHome();
    }
  };
  const PROFILE_MENU_ITEMS = [
    {
      label: "My Account",
      icon: "mdi mdi-account-circle",
      onClick: () => {},
    },
    {
      label: "Settings",
      icon: "mdi mdi-account-edit",
      onClick: () => {},
    },
    {
      label: "Support",
      icon: "mdi mdi-lifebuoy",
      onClick: () => {},
    },
    {
      label: "Lock Screen",
      icon: "mdi mdi-lock-outline",
      onClick: () => {},
    },
    {
      label: "Logout",
      icon: "mdi mdi-logout",
      onClick: () => handleSignOut(),
    },
  ];
  return (
    <Header
      end={
        <>
          <LanguageDropdown
            onChange={(value) => setLanguage(value)}
            languages={rawLanguages}
            value={language}
          />
          <NotiWrap>
            <NotificationsBar {...NOTIFICATIONS} />
          </NotiWrap>
          <li className="notification-list">
            <button
              className="nav-link dropdown-toggle end-bar-toggle arrow-none btn btn-link shadow-none d-flex align-items-center"
              onClick={() => {}}
            >
              <i className="dripicons-question noti-icon"></i>
            </button>
          </li>
          <li className="notification-list">
            <button
              onClick={() => {}}
              className="nav-link dropdown-toggle end-bar-toggle arrow-none btn btn-link shadow-none d-flex align-items-center"
            >
              <i className="dripicons-gear noti-icon"></i>
            </button>
          </li>
          <ProfileDropdown
            username={userName()}
            menuItems={PROFILE_MENU_ITEMS}
            userImage={
              <AvatarStyled className="avatar-styled">
                <span>{avartarName()?.toUpperCase()}</span>
              </AvatarStyled>
            }
          />
        </>
      }
    />
  );
};
export default DefaultHeader;
