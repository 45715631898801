import { AxiosResponse } from "axios";
import {
  MutationFunction,
  MutationOptions,
  QueryFunction,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import request from "../request";
import { QueryOptions } from "../types";
import {
  UserDto as User,
  UserAssignedRolePermissionViewPageDto,
  GetUserListParams as UserParams,
  UserPermissionDto,
} from "../../models/types";

type UserResponse = {
  get: AxiosResponse<UserAssignedRolePermissionViewPageDto>;
  getOne: AxiosResponse<User>;
  getUserPermissionsTree: AxiosResponse<any>;
  create: AxiosResponse<User>;
  delete: AxiosResponse<User>;
  deletePermissions: AxiosResponse<UserPermissionDto>;
  update: AxiosResponse<string, User>;
};

// for get action
type UserQueryKey = {
  get: ["getUsers", UserParams];
  getOne: ["getUserDetail", string];
  getUserPermissionsTree: ["getUserPermissionsTree", string, string];
};

// for mutation action
type UserVariables = {
  create: User;
  delete: number;
  deletePermissions: string;
  update: { data: User; id: string };
};

type UserAPI = {
  get: QueryFunction<UserResponse["get"], UserQueryKey["get"]>;
  getOne: QueryFunction<UserResponse["getOne"], UserQueryKey["getOne"]>;
  getUserPermissionsTree: QueryFunction<
    UserResponse["getUserPermissionsTree"],
    UserQueryKey["getUserPermissionsTree"]
  >;
  create: MutationFunction<UserResponse["create"], UserVariables["create"]>;
  delete: MutationFunction<UserResponse["delete"], UserVariables["delete"]>;
  deletePermissions: MutationFunction<
    UserResponse["deletePermissions"],
    UserVariables["deletePermissions"]
  >;
  update: MutationFunction<UserResponse["update"], UserVariables["update"]>;
};

const PREFIX = "users";

const user: UserAPI = {
  get: ({ queryKey: [, params] }) => request.get(`userPermissions`, { params }),
  getOne: ({ queryKey: [, id] }) => request.get(`${PREFIX}/${id}`),
  getUserPermissionsTree: ({ queryKey: [, userId, appId] }) =>
    request.get(`${PREFIX}/${userId}/role-permission-tree/?appIds=${appId}`),
  create: (data) => request.post(`${PREFIX}`, data),
  delete: (id) => request.delete(`${PREFIX}/${id}`),
  deletePermissions: (id) => request.delete(`${PREFIX}/${id}/permissions`),
  update: (data) => request.put(`${PREFIX}/${data.id}`, data.data),
};

export const useGetUsersQuery = (
  params: UserParams,
  options?: QueryOptions<UserResponse["get"], UserQueryKey["get"]>
) => useQuery(["getUsers", params], user.get, options);

export const useGetUserPermissionsTreeQuery = (
  userId: string,
  appId: string,
  options?: QueryOptions<
    UserResponse["getUserPermissionsTree"],
    UserQueryKey["getUserPermissionsTree"]
  >
) =>
  useQuery(
    ["getUserPermissionsTree", userId, appId],
    user.getUserPermissionsTree,
    options
  );

export const useGetUserDetailQuery = (
  id: string,
  options?: QueryOptions<UserResponse["getOne"], UserQueryKey["getOne"]>
) => useQuery(["getUserDetail", id], user.getOne, options);

export const useCreateUserMutation = (
  options?: MutationOptions<
    UserResponse["create"],
    unknown,
    UserVariables["create"]
  >
) => useMutation(["createUser"], user.create, options);

export const useDeleteUserMutation = (
  options?: MutationOptions<
    UserResponse["delete"],
    unknown,
    UserVariables["delete"]
  >
) => useMutation(["deleteUser"], user.delete, options);

export const useDeleteUserPermissionsMutation = (
  options?: MutationOptions<
    UserResponse["deletePermissions"],
    unknown,
    UserVariables["deletePermissions"]
  >
) => useMutation(["deleteUserPermissions"], user.deletePermissions, options);

export const useUpdateUserMutation = (
  options?: MutationOptions<
    UserResponse["update"],
    unknown,
    UserVariables["update"]
  >
) => useMutation(["createOrganization"], user.update, options);
