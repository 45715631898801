import React, { useState, useContext } from 'react';

type WarningModal = React.PropsWithChildren<{}>;

interface ModalData {
  message: string;
  target?: any;
  changeAccount?: any;
}

const initialState = {
  updateModal: (data: any) => data,
  modal: {},
};

const WarningModalContext = React.createContext(initialState);

function WarningModalProvider({ children }: WarningModal) {
  const updateModal = async (modal: ModalData) => {
    setState({ ...state, modal });
  };

  const [state, setState] = useState({
    ...initialState,
    updateModal,
  });

  return <WarningModalContext.Provider value={state}>{children}</WarningModalContext.Provider>;
}

const useWarningModal = () => {
  const { updateModal } = useContext(WarningModalContext);
  return updateModal;
};

const useGetWarningModal = () => {
  const { modal } = useContext(WarningModalContext);
  return modal;
};

export { WarningModalProvider, useGetWarningModal, useWarningModal };
export default WarningModalContext;
