import { AxiosResponse } from "axios";
import {
  MutationFunction,
  MutationOptions,
  QueryFunction,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import request from "../request";
import { QueryOptions } from "../types";
import {
  LocaleDto as Locale,
  LocalePageDto,
  GetApplicationsParams as LocalesParams,
  GetLocalesBySiteIdParams,
} from "../../models/types";
import { PREFIX_SITE } from "./site";

type LocaleResponse = {
  get: AxiosResponse<LocalePageDto>;
  getOne: AxiosResponse<Locale>;
  getBySite: AxiosResponse<any>;
  create: AxiosResponse<Locale>;
  delete: AxiosResponse<Locale>;
  update: AxiosResponse<string, Locale>;
};

// for get action
type LocaleQueryKey = {
  get: ["getLocales", LocalesParams];
  getOne: ["getLocaleDetail", string];
  getBySite: ["getLocalesBySite", GetLocalesBySiteIdParams];
};

// for mutation action
type LocaleVariables = {
  create: Locale;
  delete: string;
  update: { data: Locale; id: string };
};

type LocaleAPI = {
  get: QueryFunction<LocaleResponse["get"], LocaleQueryKey["get"]>;
  getOne: QueryFunction<LocaleResponse["getOne"], LocaleQueryKey["getOne"]>;
  getBySite: QueryFunction<
    LocaleResponse["getBySite"],
    LocaleQueryKey["getBySite"]
  >;
  create: MutationFunction<LocaleResponse["create"], LocaleVariables["create"]>;
  delete: MutationFunction<LocaleResponse["delete"], LocaleVariables["delete"]>;
  update: MutationFunction<LocaleResponse["update"], LocaleVariables["update"]>;
};

const PREFIX = "locales";

const locale: LocaleAPI = {
  get: ({ queryKey: [, params] }) => request.get(`${PREFIX}`, { params }),
  getOne: ({ queryKey: [, id] }) => request.get(`${PREFIX}/${id}`),
  getBySite: ({ queryKey: [, { siteId, ...params }] }) =>
    request.get(`${PREFIX_SITE}/${siteId}/availableLocales`),
  create: (data) =>
    request.post(`${PREFIX_SITE}/${data?.site?.id}/${PREFIX}`, data),
  delete: (id) => request.delete(`${PREFIX}/${id}`),
  update: (data) => request.put(`${PREFIX}/${data?.id}`, data.data),
};

export const useGetLocaleQuery = (
  params: LocalesParams,
  options?: QueryOptions<LocaleResponse["get"], LocaleQueryKey["get"]>
) => useQuery(["getLocales", params], locale.get, options);

export const useGetLocaleBySiteQuery = (
  params: GetLocalesBySiteIdParams,
  options?: QueryOptions<
    LocaleResponse["getBySite"],
    LocaleQueryKey["getBySite"]
  >
) => useQuery(["getLocalesBySite", params], locale.getBySite, options);

export const useGetLocaleDetailQuery = (
  id: string,
  options?: QueryOptions<LocaleResponse["getOne"], LocaleQueryKey["getOne"]>
) => useQuery(["getLocaleDetail", id], locale.getOne, options);

export const useCreateLocaleMutation = (
  options?: MutationOptions<
    LocaleResponse["create"],
    unknown,
    LocaleVariables["create"]
  >
) => useMutation(["createLocale"], locale.create, options);

export const useDeleteLocaleMutation = (
  options?: MutationOptions<
    LocaleResponse["delete"],
    unknown,
    LocaleVariables["delete"]
  >
) => useMutation(["deleteLocale"], locale.delete, options);

export const useUpdateLocaleMutation = (
  options?: MutationOptions<
    LocaleResponse["update"],
    unknown,
    LocaleVariables["update"]
  >
) => useMutation(["createModule"], locale.update, options);
