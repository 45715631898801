import { AxiosResponse } from "axios";
import {
  MutationFunction,
  MutationOptions,
  QueryFunction,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import request from "../request";
import { QueryOptions } from "../types";
import {
  ApplicationRoleDto as Roles,
  RolePageDto,
  GetApplicationsParams as RolesParams,
  GetRolesToAssignListParams as RolesToAssignParams,
  RoleToAssignSetupDto,
  RoleToAssignTreeDto,
} from "../../models/types";
import { PREFIX as PREFIX_ADD_ROLE } from "./applications";

type RolesResponse = {
  get: AxiosResponse<RolePageDto>;
  getRoleNotAssignYet: AxiosResponse<Roles[]>;
  getOne: AxiosResponse<Roles>;
  getRolesToAssign: AxiosResponse<RolePageDto>;
  getRolesToAssignSetup: AxiosResponse<RoleToAssignTreeDto>;
  create: AxiosResponse<Roles>;
  delete: AxiosResponse<Roles>;
  update: AxiosResponse<string, Roles>;
  deleteRolesToAssign: AxiosResponse<Roles>;
  createAndUpdateRoleToAssign: AxiosResponse<Roles>;
};

// for get action
type RolesQueryKey = {
  get: ["getRoles", RolesParams];
  getRoleNotAssignYet: ["getRoleNotAssignYet", { appId: string }];
  getOne: ["getRolesDetail", string, string];
  getRolesToAssignSetup: ["getRolesToAssignSetup", string, string];
  getRolesToAssign: ["getRolesToAssign", RolesToAssignParams];
};

// for mutation action
type RolesVariables = {
  create: Roles;
  delete: number;
  update: {
    data: { id: string; name: string; code: string; activeStatus: boolean };
    appId: string;
  };
  deleteRolesToAssign: string;
  createAndUpdateRoleToAssign: {
    roleId: string;
    data: RoleToAssignSetupDto[];
  };
};

type RolesAPI = {
  get: QueryFunction<RolesResponse["get"], RolesQueryKey["get"]>;
  getRoleNotAssignYet: QueryFunction<
    RolesResponse["getRoleNotAssignYet"],
    RolesQueryKey["getRoleNotAssignYet"]
  >;
  getOne: QueryFunction<RolesResponse["getOne"], RolesQueryKey["getOne"]>;
  getRolesToAssignSetup: QueryFunction<
    RolesResponse["getRolesToAssignSetup"],
    RolesQueryKey["getRolesToAssignSetup"]
  >;
  getRolesToAssign: QueryFunction<
    RolesResponse["getRolesToAssign"],
    RolesQueryKey["getRolesToAssign"]
  >;
  create: MutationFunction<RolesResponse["create"], RolesVariables["create"]>;
  delete: MutationFunction<RolesResponse["delete"], RolesVariables["delete"]>;
  update: MutationFunction<RolesResponse["update"], RolesVariables["update"]>;
  deleteRolesToAssign: MutationFunction<
    RolesResponse["deleteRolesToAssign"],
    RolesVariables["deleteRolesToAssign"]
  >;
  createAndUpdateRoleToAssign: MutationFunction<
    RolesResponse["createAndUpdateRoleToAssign"],
    RolesVariables["createAndUpdateRoleToAssign"]
  >;
};

const PREFIX = "applicationRoles";

const roles: RolesAPI = {
  get: ({ queryKey: [, params] }) => request.get(`${PREFIX}`, { params }),
  getRoleNotAssignYet: ({ queryKey: [, params] }) =>
    request.get(`${PREFIX}/notYetAssignedRoles`, { params }),
  getOne: ({ queryKey: [, appId, roleId] }) =>
    request.get(`/applications/${appId}/roles/${roleId}`),
  getRolesToAssignSetup: ({ queryKey: [, appId, roleId] }) =>
    request.get(
      `applicationRoles/roleToAssignSetup?appId=${appId}&roleId=${roleId}`
    ),
  getRolesToAssign: ({ queryKey: [, params] }) =>
    request.get(`${PREFIX}/roleToAssign`, { params }),
  create: ({ app, ...data }) =>
    request.post(`${PREFIX_ADD_ROLE}/${app?.id}/roles`, data),
  delete: (id) => request.delete(`${PREFIX}/${id}`),
  deleteRolesToAssign: (id) => request.delete(`${PREFIX}/${id}/roleToAssign`),
  update: (data) =>
    request.put(`/applications/${data.appId}/roles/${data.data.id}`, data.data),
  createAndUpdateRoleToAssign: ({ roleId, data }) =>
    request.put(`/applicationRoles/${roleId}/roleToAssign`, data),
};

export const useGetRolesQuery = (
  params: RolesParams,
  options?: QueryOptions<RolesResponse["get"], RolesQueryKey["get"]>
) => useQuery(["getRoles", params], roles.get, options);

export const useGetRoleNotAssignYetQuery = (
  params: { appId: string },
  options?: QueryOptions<
    RolesResponse["getRoleNotAssignYet"],
    RolesQueryKey["getRoleNotAssignYet"]
  >
) =>
  useQuery(["getRoleNotAssignYet", params], roles.getRoleNotAssignYet, options);

export const useGetRolesDetailQuery = (
  appId: string,
  roleId: string,
  options?: QueryOptions<RolesResponse["getOne"], RolesQueryKey["getOne"]>
) => useQuery(["getRolesDetail", appId, roleId], roles.getOne, options);

export const useGetRolesToAssignSetupQuery = (
  appId: string,
  roleId: string,
  options?: QueryOptions<
    RolesResponse["getRolesToAssignSetup"],
    RolesQueryKey["getRolesToAssignSetup"]
  >
) =>
  useQuery(
    ["getRolesToAssignSetup", appId, roleId],
    roles.getRolesToAssignSetup,
    options
  );

export const useGetRolesToAssignQuery = (
  params: RolesToAssignParams,
  options?: QueryOptions<
    RolesResponse["getRolesToAssign"],
    RolesQueryKey["getRolesToAssign"]
  >
) => useQuery(["getRolesToAssign", params], roles.getRolesToAssign, options);

export const useCreateRolesMutation = (
  options?: MutationOptions<
    RolesResponse["create"],
    unknown,
    RolesVariables["create"]
  >
) => useMutation(["createRoles"], roles.create, options);

export const useDeleteRolesMutation = (
  options?: MutationOptions<
    RolesResponse["delete"],
    unknown,
    RolesVariables["delete"]
  >
) => useMutation(["deleteRoles"], roles.delete, options);

export const useDeleteRolesToAssignMutation = (
  options?: MutationOptions<
    RolesResponse["deleteRolesToAssign"],
    unknown,
    RolesVariables["deleteRolesToAssign"]
  >
) => useMutation(["deleteRolesToAssign"], roles.deleteRolesToAssign, options);

export const useUpdateRoleMutation = (
  options?: MutationOptions<
    RolesResponse["update"],
    unknown,
    RolesVariables["update"]
  >
) => useMutation(["createOrganization"], roles.update, options);

export const useCreateAndUpdateRoleToAssign = (
  options?: MutationOptions<
    RolesResponse["createAndUpdateRoleToAssign"],
    unknown,
    RolesVariables["createAndUpdateRoleToAssign"]
  >
) =>
  useMutation(
    ["createAndUpdateRoleToAssign"],
    roles.createAndUpdateRoleToAssign,
    options
  );
