import React, { createContext, useReducer, useContext, Dispatch } from "react";
import { useGetUserPermissionQuery } from "../apis";
import { CURRENT_APPLICATION_CODE } from "../constants";
import { FormActions, formReducer, FormType } from "../reducers/form-reducer";
import { AuthorizationPermission } from "../models/types";
type InitialStateType = {
  form: FormType;
};
type AppProps = React.PropsWithChildren<{}>;

const initialState = {
  form: {
    isFormChanged: false,
  },
};
const AppContext = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<FormActions>;
}>({
  state: initialState,
  dispatch: () => null,
});

const useDispatch = () => {
  const { dispatch } = useContext(AppContext);
  return dispatch;
};

const useIsFormChanged = () => {
  const { state } = useContext(AppContext);
  return state.form.isFormChanged;
};

const mainReducer = ({ form }: InitialStateType, action: any) => ({
  form: formReducer(form, action),
});
function AppProvider({ children }: AppProps) {
  const [state, dispatch] = useReducer(mainReducer, initialState);
  const { data } = useGetUserPermissionQuery(
    {
      appCode: CURRENT_APPLICATION_CODE,
    },
    {
      staleTime: Infinity,
    }
  );
  const responsePermission = data?.data?.map(
    (permission: AuthorizationPermission) => permission?.code
  );
  const permissionList =
    responsePermission !== undefined
      ? JSON.stringify(responsePermission)
      : "[]";
  localStorage.setItem("permissionList", permissionList || "[]");

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
}
const useState = () => {
  const { state } = useContext(AppContext);
  return state;
};
export { AppProvider, AppContext, useState, useDispatch, useIsFormChanged };
